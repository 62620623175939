.istock-button {
  opacity: 0;

  &.visible {
    animation: fade-in 0.25s;
    animation-fill-mode: forwards;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
