@import url(https://fonts.googleapis.com/css?family=Raleway:400,600|Roboto);
.istock-button {
  opacity: 0; }
  .istock-button.visible {
    -webkit-animation: fade-in 0.25s;
            animation: fade-in 0.25s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@charset "UTF-8";
header,
footer {
  height: 4rem; }

html,
body {
  width: 100vw; }

html {
  overflow-x: hidden; }

.content-wrapper {
  min-height: calc(100vh - 5rem); }

@media (max-width: 767px) {
  header,
  footer {
    height: 6rem; }
  .related {
    display: flex;
    flex-wrap: wrap; }
  h1 {
    display: none; } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }

@-webkit-keyframes load {
  0%,
  60%,
  100% {
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial; }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); } }

@keyframes load {
  0%,
  60%,
  100% {
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial; }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); } }

* {
  box-sizing: border-box; }

header,
footer {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }

html,
body {
  padding: 0;
  margin: 0; }

body {
  font-family: "Roboto", sans-serif;
  background-color: white;
  color: #8c8c8c; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 5px 0;
  font-family: "Raleway", sans-serif; }

p {
  margin: 0 0 20px 0; }

.subheader {
  padding: 0 30px;
  height: 4rem;
  background-color: #ffd362;
  color: #1a1b1b;
  font-family: "Raleway";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.subheader.notification {
  background-color: #27c2c5; }

.subheader.notification .editing-area {
  border: 0;
  height: 100%;
  font-family: "Raleway"; }

.subheader.notification span {
  cursor: pointer; }

.subheader.notification span:not(:first-child) {
  margin-left: 20px; }

.subheader.notification span.regular {
  text-transform: none; }

.subheader.notification input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 4px;
  padding: 10px;
  border: 2px solid #f1f1f1;
  height: 43px; }

.subheader #result-count,
.subheader #result-query {
  font-weight: 600; }

.subheader .placeholder {
  display: none; }

.subheader .related-search {
  color: #1a1b1b;
  text-decoration: none;
  margin-left: 20px;
  opacity: 0.7;
  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  font-size: 0.9em; }

.subheader .related-search:hover {
  opacity: 0.9; }

.list {
  display: grid;
  list-style: none;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-left: 0;
  margin: 0; }

.list a {
  position: relative;
  text-decoration: none;
  color: inherit; }

.list a:hover::after {
  width: 0%; }

.list a::after {
  content: "";
  background-color: #ffd362;
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
  -webkit-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out; }

.list.condensed {
  grid-template-columns: 1fr 1fr 1fr; }

.list .stat-value {
  width: 125px;
  height: 125px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #04878a;
  border-radius: 4px;
  font-weight: 900;
  color: white;
  margin-right: 20px;
  font-family: "Raleway";
  letter-spacing: 2px;
  font-size: 1.5em; }

.list .stat-label {
  font-family: "Raleway"; }

.list > li > .date {
  display: block;
  text-transform: uppercase;
  font-size: 0.8em;
  margin-bottom: 5px;
  opacity: 0.7; }

.bold {
  font-weight: 600; }

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1b1b;
  padding: 20px 30px;
  width: 100%;
  font-family: "Raleway";
  min-height: 60px;
  font-size: 0.7em;
  text-transform: uppercase;
  letter-spacing: 2px; }

footer a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  opacity: 0.7;
  letter-spacing: 2px;
  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out; }

footer a:hover {
  opacity: 0.9; }

footer .social-icon {
  margin: 0 10px; }

.search__bar {
  width: 600px;
  max-width: 80%; }

.search__bar input {
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 40px;
  border: 0;
  font-size: 1.3em;
  font-family: "Raleway";
  padding: 10px;
  color: #bdbdbd;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.15); }

.search__bar input ::-webkit-input-placeholder {
  color: #a2a2a2; }

.search__bar input ::-moz-placeholder {
  color: #a2a2a2; }

.search__bar input ::-ms-input-placeholder {
  color: #a2a2a2; }

.search__bar input ::placeholder {
  color: #a2a2a2; }

.search__bar input :focus {
  border-color: #b6b6b6;
  background-color: white;
  color: #464646;
  outline: 0; }

.overlay {
  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  position: fixed;
  top: 5rem;
  left: 0;
  width: 100%;
  height: calc(100% - 5rem);
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background-color: white;
  box-shadow: 0 0 100px black;
  grid-template-columns: 1fr 3fr;
  display: grid; }

.overlay .sidebar__disclaimer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end; }

.overlay .sponsored-photo-container {
  margin-bottom: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  opacity: 0; }

.overlay .sponsored-photo-container.sponsored-photo--loaded {
  opacity: 1; }

.overlay .action:not(:first-child) {
  margin-left: 10px; }

.overlay__image {
  height: calc(100vh - 5rem);
  pointer-events: none; }

.overlay__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.overlay__image--placeholder {
  position: absolute;
  left: calc(50% + 45px);
  top: calc(50% - 15px);
  z-index: -1; }

.overlay__sidebar {
  overflow: hidden;
  padding: 20px;
  height: calc(100vh - 5rem);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.overlay__sidebar .sidebar__title {
  display: block;
  margin-bottom: 5px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 2px;
  font-size: 0.7em;
  color: #1a1b1b; }

.overlay__sidebar .sidebar__detail {
  margin-bottom: 20px;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Raleway";
  color: #1a1b1b; }

.overlay__sidebar .disclaimer {
  font-size: 0.7em;
  text-transform: none;
  text-transform: initial; }

.circle {
  position: relative;
  -webkit-animation: load 1s ease-in-out infinite;
          animation: load 1s ease-in-out infinite; }

.circle:nth-child(2) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }

.circle:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }

button.action {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0; }

.notice {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  margin-bottom: 0; }

.nav {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: "Raleway";
  font-size: 0.7em; }

.nav li:not(:first-child) {
  margin-left: 25px; }

.nav li a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  opacity: 0.7;
  letter-spacing: 2px;
  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out; }

.nav li a:hover {
  opacity: 0.9; }

.nav li .user-icon {
  border-radius: 2px;
  border: 1px solid #ffd362;
  object-fit: cover;
  position: relative;
  top: 2px; }

#gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  grid-auto-rows: 20rem;
  grid-auto-flow: dense;
  grid-gap: 20px;
  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  padding: 3rem; }

#gallery .extend {
  grid-column: span 10; }

#gallery.hidden {
  opacity: 0; }

.item {
  position: relative; }

.item.premium::after {
  content: "PREMIUM";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffd362;
  color: black;
  padding: 4px 8px;
  border-radius: 0 0 0 4px; }

.item:hover .item__overlay {
  opacity: 1; }

.item a {
  color: inherit;
  text-decoration: none;
  position: relative;
  position: relative;
  text-decoration: none;
  color: inherit; }

.item a::after {
  content: "";
  background-color: #ffd362;
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
  -webkit-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out; }

.item a:hover::after {
  width: 0%; }

.item .item__image {
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
  -webkit-transition: box-shadow 0.5s ease-in-out, border-color 0.25s ease-in-out, opacity 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out, border-color 0.25s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  grid-column: 1/-1;
  grid-row: 1/-1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer; }

.item .item__image::before {
  content: "Oh no.. looks like the provider changed the photo url. We're working on a way to fix this - but for now, accept our apologies \1F62D";
  font-size: 1rem;
  display: flex;
  margin-bottom: 1rem; }

.item .item__image.lazyloaded {
  opacity: 1; }

.item__overlay {
  font-family: "Raleway";
  opacity: 0;
  position: absolute;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 30px;
  bottom: 10px;
  background-color: #ffd362;
  border-radius: 5px;
  left: 10px;
  -webkit-transition: border-radius 0.25s ease-in-out, width 0.25s 0.25s ease-in-out, opacity 0.15s ease-in-out;
  transition: border-radius 0.25s ease-in-out, width 0.25s 0.25s ease-in-out, opacity 0.15s ease-in-out;
  overflow: hidden; }

.item__overlay .item__meta {
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 30px;
  background-color: #1a1b1b;
  color: white;
  padding: 0 10px;
  border-radius: 0 5px 5px 0; }

.item__overlay .item__meta .meta {
  margin: 0 10px; }

.item__overlay .item__meta .meta.author {
  text-transform: capitalize; }

.actions {
  display: flex;
  position: relative; }

.action {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat; }

.action:hover {
  cursor: pointer; }

.action.search {
  background-image: url("/images/actions/search.svg"); }

.action.full-heart {
  background-image: url("/images/actions/full-heart.svg"); }

.action.close {
  background-image: url("/images/actions/x.svg");
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 15px;
  background-color: white;
  z-index: 999; }

.action.close:hover {
  -webkit-animation: spin 0.5s ease-in-out;
          animation: spin 0.5s ease-in-out; }

.action.download {
  background-image: url("/images/actions/download.svg");
  position: absolute;
  width: 20px;
  height: 20px;
  left: 5px;
  top: 5px; }

.action.icon--white.heart {
  background-image: url("/images/actions/heart.svg"); }

.action.icon--black.heart {
  background-image: url("/images/actions/heart-dark.svg"); }

@media (max-width: 767px) {
  .action.settings,
  .support-item,
  header h1,
  .main-content .list {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, 1fr); }
  #gallery {
    grid-template-columns: 1fr;
    padding: 1rem; }
  .subheader-new {
    padding: 2rem 1rem 1rem 1rem !important; }
  footer {
    flex-direction: column;
    align-items: unset; }
  footer > span:not(:last-child) {
    margin-bottom: 10px; }
  footer .social-icon {
    margin-left: 0; }
  .header__block {
    align-items: center;
    flex: 1 0 100% !important;
    flex-direction: initial; }
  .nav {
    margin-top: 10px;
    justify-content: center;
    max-width: unset;
    margin: 0 auto; }
  .subheader {
    flex-wrap: wrap;
    justify-content: start; }
  .subheader .subheader__item:first-child {
    margin-bottom: 10px; }
  .subheader .subheader__item:only-child {
    margin-bottom: 0; }
  .subheader .related-search {
    margin-left: 0;
    margin-right: 5px; } }

.gallery__placeholder {
  text-align: center;
  display: none; }

.underline {
  position: relative;
  text-decoration: none;
  color: inherit; }

.underline:hover::after {
  width: 0%; }

.underline::after {
  content: "";
  background-color: #ffd362;
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
  -webkit-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out; }

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
          appearance: none; }

.ad {
  background-color: #efefef;
  border-radius: 5px;
  background-position: 0%;
  background-size: 0%;
  background-repeat: no-repeat;
  overflow: hidden; }

.ad .item__image {
  opacity: 1; }

.landing {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: black; }

.landing .hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7; }

.landing .hero__text {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0; }

.landing .hero__text h2 {
  width: 600px;
  max-width: 80%;
  color: white;
  font-size: 2em;
  margin-bottom: 10px; }

.landing .hero__text .search__bar input {
  width: 100%;
  position: relative;
  background-color: white;
  color: #373737;
  font-size: 1.2em;
  font-weight: 600;
  height: 50px;
  padding: 20px; }

.landing .action.search {
  position: absolute;
  top: 10px;
  right: 20px;
  opacity: 0.3; }

@media (max-width: 767px) {
  .hero__text h2,
  .hero__text .search__bar {
    z-index: 999; }
  .landing .logo {
    margin: 0 auto; }
  .landing .logo h1 {
    display: inherit; }
  .landing .search__bar {
    font-size: 0.9em !important; }
  .landing .message {
    grid-column: span 2; } }

.page-selector {
  margin: 0 auto;
  padding: 40px 0;
  width: 300px;
  max-width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.page-selector .page {
  color: black; }

.page-selector > * {
  flex-basis: 33%;
  display: flex;
  justify-content: center; }

.page-selector svg {
  height: 20px;
  width: 20px; }

.no-extras {
  padding: 40px 0; }

.no-extras.login {
  width: 90%;
  max-width: 700px;
  margin: 0 auto; }

.methods {
  width: 700px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.methods h2 {
  color: #191a1a;
  margin-bottom: 20px; }

.methods .status {
  font-size: 0.8rem; }

.methods button,
.methods input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  width: 100%;
  height: 40px;
  max-width: 300px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  font-family: "Roboto";
  position: relative;
  font-size: 0.7rem;
  cursor: pointer;
  background-color: white; }

.methods button:not(:last-child),
.methods input:not(:last-child) {
  margin-bottom: 15px; }

.methods button:hover::before,
.methods input:hover::before {
  width: 20px; }

.methods button:not(input)::before,
.methods input:not(input)::before {
  content: "";
  height: 100%;
  width: 10px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px 0 0 2px;
  -webkit-transition: width 0.25s ease;
  transition: width 0.25s ease; }

.methods button.google::before,
.methods input.google::before {
  background-color: #ea4335; }

.methods button.facebook::before,
.methods input.facebook::before {
  background-color: #3b5998; }

.methods button.email::before,
.methods input.email::before {
  background-color: #1da1f2; }

.methods button[type="email"]:focus,
.methods input[type="email"]:focus {
  outline: 0;
  -webkit-transition: border-color 0.25s ease;
  transition: border-color 0.25s ease; }

.methods button[type="email"]:focus.good,
.methods input[type="email"]:focus.good {
  border-color: #72e510; }

.methods button[type="email"]:focus.bad,
.methods input[type="email"]:focus.bad {
  border-color: #ff9898; }

.register {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.lists {
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0 3rem; }

[for="active-list"] {
  opacity: 0.7;
  position: relative;
  top: 1px; }

.active-list-changer {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  border-radius: 2px;
  padding: 5px;
  margin-left: 5px; }

.saved-in,
.not-saved-in,
.lists li {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.25s ease;
  transition: border-color 0.25s ease;
  font-family: "Raleway";
  border: 2px solid #f1f1f1;
  margin: 0 5px 5px 0;
  padding: 10px; }

.saved-in.active,
.not-saved-in.active,
.lists li.active {
  border: 2px solid #ffd362; }

.saved-in:focus,
.not-saved-in:focus,
.lists li:focus {
  outline: 0; }

.saved-in {
  border: 2px solid #ffd362 !important; }

.tos {
  padding: 3rem; }

.has-loading-icon {
  display: flex;
  justify-content: center;
  align-items: center; }

.loading-icon {
  height: 100px;
  width: 100px; }

.loading-icon circle:nth-child(1) {
  -webkit-animation: move-0 2.5s ease-in-out 0.15s infinite, fade-in 1.5s ease;
          animation: move-0 2.5s ease-in-out 0.15s infinite, fade-in 1.5s ease; }

.loading-icon circle:nth-child(2) {
  -webkit-animation: move-1 2.5s ease-in-out 0.3s infinite, fade-in 1.5s ease;
          animation: move-1 2.5s ease-in-out 0.3s infinite, fade-in 1.5s ease; }

.loading-icon circle:nth-child(3) {
  -webkit-animation: move-2 2.5s ease-in-out 0.45s infinite, fade-in 1.5s ease;
          animation: move-2 2.5s ease-in-out 0.45s infinite, fade-in 1.5s ease; }

@-webkit-keyframes move-0 {
  0%,
  100% {
    cx: 49.5;
    cy: 5; }
  50% {
    cx: 50.5;
    cy: 95; } }

@keyframes move-0 {
  0%,
  100% {
    cx: 49.5;
    cy: 5; }
  50% {
    cx: 50.5;
    cy: 95; } }

@-webkit-keyframes move-1 {
  0%,
  100% {
    cx: 17.5;
    cy: 18.75; }
  50% {
    cx: 82.5;
    cy: 81.25; } }

@keyframes move-1 {
  0%,
  100% {
    cx: 17.5;
    cy: 18.75; }
  50% {
    cx: 82.5;
    cy: 81.25; } }

@-webkit-keyframes move-2 {
  0%,
  100% {
    cx: 5;
    cy: 49.5; }
  50% {
    cx: 95;
    cy: 50.5; } }

@keyframes move-2 {
  0%,
  100% {
    cx: 5;
    cy: 49.5; }
  50% {
    cx: 95;
    cy: 50.5; } }

@-webkit-keyframes fade-in {
  0%,
  25% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0%,
  25% {
    opacity: 0; }
  100% {
    opacity: 1; } }

button.no-button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  color: inherit;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer; }

button.no-button.styled {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  padding: 4px 10px; }

button.no-button.styled:not(:last-child) {
  margin-right: 15px; }

.modal-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; }

.modal-wrapper .register.methods {
  width: unset;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6); }

header {
  width: 100%;
  height: 5rem;
  background-color: #fcfcfc;
  color: #303030;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99999; }

header.on-landing {
  background-color: unset;
  box-shadow: unset;
  position: fixed;
  width: 100%; }

header.on-landing h1 {
  color: #fcfcfc; }

@media (max-width: 767px) {
  header.on-landing .logo * {
    display: block !important; } }

@media (max-width: 767px) {
  header:not(.on-landing) .logo * {
    display: none !important; } }

header .logo {
  font-size: 0.7rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0; }

header .logo h1 {
  margin-bottom: 0;
  display: flex; }

header .logo img {
  width: 9rem;
  max-width: unset; }

header .search__bar {
  margin-left: 2rem; }

@media (max-width: 767px) {
  header .search__bar {
    margin-left: 0;
    width: auto; }
  header .search__bar input {
    width: unset; } }

header .search__bar input {
  background-color: #e9e9e9;
  color: #5b5b5b;
  width: 70%; }

header h1 {
  color: #303030; }

header.ready nav {
  opacity: 1; }

header nav {
  margin-left: auto;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; }

header nav .menu {
  padding: 0;
  width: 1.75rem;
  height: 1rem;
  color: transparent;
  margin-right: 2rem;
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  background: -webkit-gradient(linear, left top, left bottom, from(#303030), color-stop(20%, #303030), color-stop(20%, transparent), color-stop(40%, transparent), color-stop(40%, #303030), color-stop(60%, #303030), color-stop(60%, transparent), color-stop(80%, transparent), color-stop(80%, #303030), to(#303030));
  background: linear-gradient(to bottom, #303030, #303030 20%, transparent 20%, transparent 40%, #303030 40%, #303030 60%, transparent 60%, transparent 80%, #303030 80%, #303030 100%); }

header nav .menu:hover, header nav .menu.active {
  -webkit-transform: skewX(-15deg);
          transform: skewX(-15deg); }

header nav .react-gravatar {
  border-radius: 50%;
  width: 40px;
  height: 40px; }

header nav .get-started {
  padding: 8px 12px;
  background-color: #ffd362;
  border-radius: 4px; }

.sidebar {
  width: 20rem;
  height: calc(100% - 5rem);
  position: fixed;
  top: 5rem;
  left: 100vw;
  background-color: #303030;
  color: #fcfcfc;
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  -webkit-transition: left 0.25s ease;
  transition: left 0.25s ease;
  z-index: 99999; }

.sidebar.open {
  left: calc(100% - 20rem); }

.sidebar a,
.sidebar button {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  display: block; }

.sidebar h2:not(:first-of-type) {
  margin-top: 2rem; }

.subheader-new {
  padding: 4rem 3rem 1rem 3rem;
  font-size: 1.3rem;
  color: #303030;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }

.subheader-new h2 {
  margin-bottom: 0; }

.subheader-new small {
  font-size: 1rem; }

.synonyms {
  display: flex;
  flex-wrap: wrap; }

.synonyms .synonym {
  background-color: #fcfcfc;
  color: #b0b0b0;
  font-size: 0.9rem;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 8px 12px;
  position: relative;
  bottom: 0;
  -webkit-transition: bottom 0.2s ease;
  transition: bottom 0.2s ease; }

.synonyms .synonym:not(:last-child) {
  margin-right: 1rem; }

.synonyms .synonym:hover {
  bottom: 3px; }

.login-modal {
  padding: 2rem;
  position: fixed;
  top: 5rem;
  height: calc(100% - 5rem);
  background-color: white;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 99999; }

@media (max-width: 767px) {
  header .search__bar input {
    width: 90%; }
  .overlay__image--placeholder {
    display: none; }
  .overlay {
    grid-template-columns: unset;
    grid-template-rows: 1fr 4fr;
    display: grid; }
  .overlay .overlay__sidebar {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content; }
  .overlay .overlay__image {
    height: 100%;
    flex-grow: 1; }
  .overlay .photo-title {
    display: none; }
  .get-started {
    display: none; }
  .sponsored-photo {
    display: none; }
  .sponsored-photo-link {
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #ffd362;
    margin-bottom: 0 !important; }
  .sidebar__disclaimer {
    margin-bottom: 0 !important; }
  .synonyms {
    margin-top: 2rem; }
  .synonyms .synonym {
    margin-top: 0.5rem; } }

.sponsored-photo-link {
  text-decoration: none; }

.sponsored-photo {
  flex-basis: 50%;
  width: 50%;
  max-height: 300px; }

.sponsored-photo img {
  object-position: center;
  object-fit: cover;
  height: 100%;
  width: 100%; }

.sidebar__disclaimer {
  margin-bottom: 30px; }

.buttons {
  display: flex; }

.buttons .subheader__button {
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #f9f9f9;
  border: 1px solid #f2f2f2;
  margin-top: 1rem;
  font-size: 0.8rem;
  color: inherit;
  text-decoration: none; }

.buttons .subheader__button:not(:first-child) {
  margin-left: 1rem; }

.announcement-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 24px;
  background-color: rgba(249, 249, 249, 0.9);
  color: #303030;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.announcement-bar .seen {
  background-color: #fcfcfc;
  color: #7d7d7d;
  border: 1px solid #efefef;
  padding: 4px 8px;
  border-radius: 4px; }

.announcement-bar a {
  color: inherit; }

.suggestions {
  width: 600px;
  max-width: 80%;
  color: white;
  margin-top: 10px; }

.suggestions button {
  white-space: pre; }

.suggestions span {
  text-decoration: underline; }

.sponsored-photos {
  display: flex; }

